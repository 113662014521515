<template>
  <div class="heading">
    <div class="heading-text">
      <strong>The best place to buy top quality dry fruits for a healthy lifestyle</strong>
      <div class="sub-heading">
        <button class="nav-prod-btn" style="background-color: #ffbd59;">
          <router-link style="background: none;" :to="{ name: 'products' }">Get Started</router-link>
          <!-- Get Started -->
        </button>
        <!-- <Button>Learn More...</Button> -->
      </div>
    </div>

    <img src="/assets/logo_.png" alt="" class="logo-big" />
  </div>
</template>

<style scoped>
.heading {
  font-size: 2.5rem;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 50px 50px;
  justify-content: center;
  align-items: center;
  /* font-family: "Allura", cursive; */
  /* font-family: "Merienda", cursive; */
  /* color: white; */
  gap: 20px;
  /* background: white; */
  color: #33691e;
  height: 35rem;

  /* background-color: rgba(0, 0, 0, 1); */
  /* border: 1px solid red; */
  /* margin: auto; */
  /* max-width: 95%; */
}

.sub-heading {
  display: flex;
  flex-direction: row;
  /* gap: 50px; */
  justify-content: space-evenly;
  align-items: center;
  margin: 40px 0;
}

.sub-heading * {
  border: none;
  border-radius: 42px;
  padding: 12px 18px;
  /* border: 1px solid rgb(49, 3, 3); */
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.sub-heading *:hover {
  scale: 1.02;
  transition: all 0.1s;
  color: white;
}

.sub-heading * {
  /* background-color: navy; */
  /* background: rgb(49, 3, 3); */
  /* background-color: #556b2f; */

  color: white;
}

/* .sub-heading :last-child {
  background: transparent;
} */

.logo-big {
  height: auto;
  width: 400px;
  border-radius: 5%;
  object-fit: contain;
  margin: 0.2rem auto;
}

@media only screen and (max-width: 768px) {
  .heading {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .logo-big {
    display: none;
  }



  .heading-text {
    padding: 0 5px;
    font-size: 1.3rem;
    /* font-family: "Allura", cursive;
    font-family: "Merienda", cursive; */
    padding: 5px;
  }

  .logo-big {
    height: 150px;
    width: 200px;
    margin-right: 1rem;

  }

  .sub-heading * {
    font-size: 1rem;
  }


  .heading {
    gap: 5px;
  }



  .heading-text {
    margin-top: 3rem;
    /* border: 1px solid; */
    justify-content: center;
    align-items: center;

  }
}
</style>
