import { auth } from "@/main";
import {
  createUserWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { defineStore } from "pinia";

export const authStore = defineStore("authStore", {
  state: () => ({
    user: null,
    login_msg: {},
    signup_msg: {},
  }),
  actions: {
    // signup action
    async signup(email, password) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCred) => {
          this.user = userCred.user;
          this.isLoggedIn = true;
          setPersistence(auth, browserLocalPersistence);
          this.signup_msg = userCred;
        })
        .catch((error) => {
          this.signup_msg = error;
        });

    },

    async signout() {
      await auth.signOut();
      this.user = false;
    },

    // LOGIN
    async login(email, password) {
      await signInWithEmailAndPassword(auth, email, password)
        .then((userCred) => {
          this.user = userCred.user;
          this.isLoggedIn = true;
          setPersistence(auth, browserLocalPersistence);
          this.login_msg = userCred;
        })
        .catch((error) => {
          this.login_msg = error;
        });
    },
  },
});
