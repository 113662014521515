<template>
  <div class="payment-page1-container">
    <form class="payment-page1-form">
      <div class="payment-page1-container01">
        <label class="payment-page1-text">CHECKOUT</label>
      </div>
      <div class="payment-page1-container02">
        <svg viewBox="0 0 1024 1024" class="payment-page1-icon">
          <path
            d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"
          ></path>
        </svg>
        <a href="https://example.com" target="_blank" rel="noreferrer noopener">
          <span>Back to shopping</span>
          <br />
        </a>
      </div>
      <div class="payment-page1-checkoutprocess">
        <div class="payment-page1-container03">
          <label class="payment-page1-text03">
            <span>STEP</span>
            <br />
            <span>1</span>
          </label>
        </div>
        <div class="payment-page1-container04">
          <label class="payment-page1-text07">
            <span>STEP</span>
            <br />
            <span>2</span>
            <br />
          </label>
        </div>
        <div class="payment-page1-container05">
          <label class="payment-page1-text12">
            <span>STEP</span>
            <br />
            <span>3</span>
            <br />
          </label>
        </div>
      </div>
      <div class="payment-page1-container06">
        <div class="payment-page1-container07">
          <img
            alt="image"
            src="assets/public/around%20the%20world-pana.svg"
            class="payment-page1-image"
          />
        </div>
        <form
          class="payment-page1-container08"
          @submit.prevent="sendDataToServer"
        >
          <div class="payment-page1-container09">
            <label class="payment-page1-text17">
              <span>&nbsp;Shipping A</span>
              <span class="payment-page1-text19">DDRESS</span>
            </label>
          </div>
          <div class="payment-page1-namediv">
            <input
              type="text"
              required="true"
              placeholder="First name"
              v-model="firstName"
              class="payment-page1-textinput input"
            />
            <input
              type="text"
              required="true"
              placeholder="Last name"
              v-model="lastName"
              class="payment-page1-textinput1 input"
            />
          </div>
          <div class="payment-page1-addressdiv">
            <input
              type="text"
              required="true"
              placeholder="House/ village/ town/ Area"
              class="payment-page1-textinput2 input"
              v-model="userStreet"
            />
            <div class="payment-page1-container10">
              <input
                type="text"
                required="true"
                placeholder="District"
                class="payment-page1-textinput3 input"
                v-model="userDistrict"
              />
              <input
                type="text"
                required="true"
                placeholder="State"
                v-model="userState"
                class="payment-page1-textinput4 input"
              />
            </div>
            <div
              class="payment-page1-container10"
              style="display: flex; justify-content: space-between"
            >
              <input
                type="text"
                required="true"
                placeholder="Phone"
                v-model="phone"
                maxlength="10"
                class="payment-page1-textinput5 input"
                style="width: 47%; height: auto"
              />
              <input
                type="text"
                required="true"
                placeholder="Pin Code"
                v-model="userPincode"
                maxlength="6"
                class="payment-page1-textinput5 input"
                style="width: 47%; height: auto"
              />
            </div>
          </div>
          <div
            class="response"
            :style="success ? success_style : error_style"
            v-if="response"
          >
            {{ response }}
          </div>
          <button
            type="submit"
            class="payment-page1-button button"
            @submit.prevent="sendDataToServer"
          >
            Continue
          </button>
        </form>
      </div>
    </form>
  </div>
</template>

<script>
import { authStore } from "@/store";
import { productStore } from "@/store/productStore";
import { db } from "@/main";
import { doc, getDoc, setDoc } from "firebase/firestore";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      userStreet: "",
      userDistrict: "",
      userState: "",
      userPincode: "",
      server_prod: "php/user_details.php",
      success: false,
      response: "",
      success_style: "color:#084706",
      error_style: "color:red",
      get_data_api: "php/get_user.php",
      resp_data: {},
    };
  },

  methods: {
    async sendDataToServer() {
      let data = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        phone: this.phone,
        userStreet: this.userStreet,
        userDistrict: this.userDistrict,
        userState: this.userState,
        userPincode: this.userPincode,
      };

      try {
        await setDoc(doc(db, "users", this.email), {
          data,
        });
        this.$router.push({ name: "init_payment" });
      } catch (error) {
        console.error("Error uploading user details! Error: ", error);
      }
    },

    async getUserDetails() {
      console.log(productStore().selectedProducts);
      try {
        const userDocRef = doc(db, "users", this.email);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          this.$router.replace("/initiate-payment");
        }
      } catch (error) {
        console.error("Error fetching user details! Error: ", error);
      }
    },

    clearForm() {
      this.firstName = "";
      this.lastName = "";
      this.userStreet = "";
      this.userDistrict = "";
      this.userState = "";
      this.userPincode = "";
    },
  },

  created() {
    const script = document.createElement("script");
    script.setAttribute("data-section-id", "navbar");
    script.src = "https://unpkg.com/@teleporthq/teleport-custom-scripts";
    script.async = true;
    document.head.appendChild(script);
  },

  mounted() {
    this.getUserDetails();
  },

  computed: {
    email() {
      return authStore().user.email;
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  border: 1px solid black;
  color: black;
  background: rgba(255, 255, 255, 0.7);
}

input[type="text"]:active,
input[type="text"]:focus {
  background: rgba(255, 255, 255, 0.7);
}

.response {
  padding: 5px;
  font-size: 1.2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  display: inline;
  margin: 8px auto;
  border-radius: 4rem;
}

.payment-page1-container {
  width: 100%;
  display: flex;
  /* overflow: auto; */
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.payment-page1-form {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
}

.payment-page1-container01 {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page1-text {
  font-size: 25px;
  align-self: center;
}

.payment-page1-container02 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
}

.payment-page1-icon {
  width: 24px;
  height: 24px;
}

.payment-page1-checkoutprocess {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  height: 116px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}

.payment-page1-container03 {
  flex: 0 0 auto;
  width: 122px;
  height: 122px;
  /* height: 100%; */
  display: flex;
  box-shadow: 5px 5px 10px 0px #d8bebe;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #e6eeb8;
}

.payment-page1-text03 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page1-container04 {
  flex: 0 0 auto;
  width: 122px;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
}

.payment-page1-text07 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page1-container05 {
  flex: 0 0 auto;
  width: 122px;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
}

.payment-page1-text12 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page1-container06 {
  gap: 0;
  width: 100%;
  height: 657px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.payment-page1-container07 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.payment-page1-image {
  width: 100%;
  height: 750px;
  align-self: flex-start;
  object-fit: cover;
}

.payment-page1-container08 {
  width: 50%;
  height: 100%;
  display: flex;
  opacity: 1;
  padding: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  background-image: url("/public/assets/public/around\ the\ world-pana.svg");
  background-position: center;
}

/* /home/blackfire/Documents/Clients/delight-2.0.backup/delight-2.0/public/assets/public/around the world-pana.svg */

.payment-page1-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 34px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page1-text17 {
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
}

.payment-page1-text19 {
  text-transform: lowercase;
}

.payment-page1-namediv {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}

.payment-page1-textinput {
  width: 100%;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
}

.payment-page1-textinput:hover {
  box-shadow: none;
}

.payment-page1-textinput1 {
  width: 100%;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
}

.payment-page1-textinput1:hover {
  /* box-shadow: 5px 5px 10px 0px #9b8989; */
  box-shadow: none;
}

.payment-page1-addressdiv {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}

.payment-page1-textinput2 {
  width: 100%;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
}

.payment-page1-textinput2:hover {
  box-shadow: none;
}

.payment-page1-container10 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
}

.payment-page1-textinput3 {
  width: 100%;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
}

.payment-page1-textinput3:hover {
  box-shadow: none;
}

.payment-page1-textinput4 {
  width: 100%;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
}

.payment-page1-textinput4:hover {
  box-shadow: none;
}

.payment-page1-textinput5 {
  width: 45%;
  height: 36px;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
}

.payment-page1-textinput5:hover {
  box-shadow: none;
}

.payment-page1-button {
  transition: 0.3s;

  border-radius: 50px;
  background-color: #bbfb7a;
}

.payment-page1-button:hover {
  box-shadow: 5px 5px 10px 0px #887d7d;
  border-radius: 50px;
}

@media (max-width: 991px) {
  .payment-page1-form {
    height: 905px;
  }

  .payment-page1-container06 {
    gap: var(--dl-space-space-unit);
    height: 593px;
  }

  .payment-page1-image {
    width: 100%;
    height: 526px;
    padding-right: 0px;
  }

  .payment-page1-namediv {
    height: 68px;
    flex-direction: row;
  }

  .payment-page1-textinput {
    width: 208px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .payment-page1-textinput1 {
    width: 208px;
  }

  .payment-page1-addressdiv {
    height: 244px;
  }

  .payment-page1-textinput2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }

  .payment-page1-container10 {
    height: auto;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
  }

  .payment-page1-textinput3 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}

@media (max-width: 767px) {
  .payment-page1-container02 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .payment-page1-container06 {
    justify-content: center;
  }

  .payment-page1-container07 {
    display: none;
  }

  .payment-page1-container08 {
    width: 546px;
    height: 587px;
  }

  .payment-page1-namediv {
    gap: var(--dl-space-space-halfunit);
  }

  .payment-page1-textinput {
    width: 100%;
  }

  .payment-page1-textinput1 {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .payment-page1-container01 {
    margin-bottom: 0px;
  }

  .payment-page1-container02 {
    width: auto;
    align-items: center;
    margin-left: 00px;
    margin-right: 00px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .payment-page1-checkoutprocess {
    gap: var(--dl-space-space-unit);
  }
}
</style>
