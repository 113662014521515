<template>
  <div class="home-faq">
    <div class="home-faq-container">
      <div class="home-faq1">
        <div class="home-container10">
          <span class="home-text08 sectionTitle">
            <span style="font-size: 3rem;">FAQ</span>
            <br />
          </span>
          <h2 class="home-text11 heading2">Common questions</h2>
          <span class="home-text12">
            <span>
              Here are some of the most common questions that we get.
            </span>
            <br />
            <span>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </span>
            <span>
              <span>
                <span></span>
                <span></span>
              </span>
              <span>
                <span></span>
                <span></span>
              </span>
            </span>
          </span>
        </div>
        <div class="home-container11">
          <div class="question-container">
            <span class="question-text heading4">
              <span>What is Delight de Heaven?</span>
            </span>
            <span class="question-text1">
              <span>
                Delight de Heaven is an e-commerce website that specializes in
                selling dry fruits.
              </span>
            </span>
          </div>
          <div class="question-container">
            <span class="question-text heading4">
              <span>
                What types of dry fruits are available on Delight de Heaven?
              </span>
            </span>
            <span class="question-text1">
              <span>
                Delight de Heaven offers a wide range of dry fruits including
                almonds, cashews, pistachios, raisins, and more.
              </span>
            </span>
          </div>
          <div class="question-container">
            <span class="question-text heading4">
              <span> Are the dry fruits on Delight de Heaven organic? </span>
            </span>
            <span class="question-text1">
              <span>
                Yes, we strive to provide organic dry fruits to our customers.
                However, please check the product descriptions for specific
                details.
              </span>
            </span>
          </div>
          <div class="question-container">
            <span class="question-text heading4">
              <span>How can I place an order on Delight de Heaven?</span>
            </span>
            <span class="question-text1">
              <span>
                To place an order, simply browse through our collection of dry
                fruits, select the desired items, and proceed to the checkout
                page.
              </span>
            </span>
          </div>
          <div class="question-container">
            <span class="question-text heading4">
              <span>
                What payment methods are accepted on Delight de Heaven?
              </span>
            </span>
            <span class="question-text1">
              <span>
                We accept all major credit cards, debit cards, and online
                payment methods such as PayPal and Google Pay.
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
* {
  text-align: start
}
</style>