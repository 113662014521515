import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";


// firebase stuff

const firebaseConfig = {
    apiKey: "AIzaSyBfBi2HGkGNqduMQwXzQ8QciVgyyxSiijw",
    authDomain: "rising-daylight-325319.firebaseapp.com",
    projectId: "rising-daylight-325319",
    storageBucket: "rising-daylight-325319.appspot.com",
    messagingSenderId: "700566060214",
    appId: "1:700566060214:web:2729822418e0164b68fa17",
};

// Initialize firebase
const firebaseApp = initializeApp(firebaseConfig);

// used for firestore refs
const db = getFirestore(firebaseApp);

// here we can export reusable database references
// export const prodRef = collection(db, "products");

// initialize firebase auth and google auth provider
const auth = getAuth();
// const provider = new GoogleAuthProvider();

const storage = getStorage(firebaseApp);

export { firebaseApp, auth,  storage, db };



