const cart_add_API = "http://localhost:3000/public/php/add_to_cart.php";
const cart_add_server_API = "php/add_to_cart.php";

// Add the add to cart functionality
import { getFirestore, collection, addDoc } from "firebase/firestore";

async function addToCart(product, authStore, isLoggedIn, quantity) {
  return new Promise(async (resolve, reject) => {
    if (isLoggedIn) {
      try {
        const db = getFirestore();
        const data = {
          user_id: authStore.user.uid,
          product_id: product.id,
          product_price: product.price,
          product_qty: quantity,
          timestamp: new Date(), // Optionally add a timestamp
        };

        await addDoc(collection(db, "cartItems"), data);
        resolve(true);
      } catch (error) {
        console.error("Error adding document: ", error);
        reject(error);
      }
    } else {
      resolve(false); // or reject("User not logged in") based on your preference
    }
  });
}

// Function to update cart items
function updateCartItems(data) {
  const update_cart_API = "http://localhost:3000/public/php/update_cart.php";
  const update_cart_server_API = "php/update_cart.php";
  fetch(update_cart_server_API, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.status == "Ok") {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}
export { addToCart, updateCartItems };
