<template>
  <div>
    <div>
      <!-- <link href="./signup-page.css" rel="stylesheet" /> -->

      <div class="signup-page-container">
        <div class="signup-page-container1">
          <div class="signup-page-container2">
            <div class="signup-page-container3">
              <img alt="image" src="assets/logo.png" class="signup-page-image" />
            </div>
            <div class="signup-page-container4">
              <span class="signup-page-text">
                <span>New on</span>
                <br />
                <span class="signup-page-text3">Delight</span>
                <span class="signup-page-text4">De</span>
                <span class="signup-page-text5">Heaven..?</span>
                <br class="signup-page-text6" />
                <span>Register here now</span>
                <br />
              </span>
            </div>
          </div>
          <div class="signup-page-container5">
            <form class="signup-page-container6" @submit.prevent="signUp">
              <label class="signup-page-label">Sign up</label>

              <input type="text" id="email_ID" required="" placeholder="E-mail ID" class="signup-page-textinput1 input"
                v-model="email" />
              <input type="password" id="Create Password" required="" placeholder="Create Password"
                class="signup-page-textinput2 input" v-model="password" @keyup="checkPasswordValidation" />
              <input type="password" id="Confirm Password" required="" placeholder="Confirm Password"
                class="signup-page-textinput3 input" v-model="confirmedPassword" @keyup="
                  if (validatePassword()) {
                  checkPasswords();
                }
                  " />
              <label style="color: white">{{ validationText }}</label>
              <label>{{ response }}</label>
              <button type="submit" class="signup-page-button button" id="submit-btn" :disabled="!submitDisabled"
                style="display: flex; justify-content: center; align-items: center;">

                <span v-if="!signing_up">Sign Up</span>
                <Spinner class="spinner" v-if="signing_up"></Spinner>

              </button>

              <span>Already have an acount?
                <router-link to="login" style="color: white">LOGIN</router-link></span>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authStore } from "@/store";
import Spinner from "@/components/Spinner.vue";
import "../../public/styles/style.css";
import "../../public/styles/index.css";
// import "../../public/styles/"

export default {

  components: {
    Spinner
  },

  data() {
    return {
      authStore: authStore(),
      email: "",
      password: "",
      confirmedPassword: "",
      validationText: "",
      submitDisabled: true,
      validPasswordRegex: new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
      ),
      wrongPasswordText:
        "Password must contain at least one uppercase character, one special character ",
      response: '',
      create_user_dev: 'http://localhost:3000/public/php/create_user.php',
      create_user_prod: 'php/create_user.php',


      signing_up: false
    };
  },
  methods: {
    async signUp() {

      this.signing_up = true;

      await this.authStore.signup(this.email, this.password);
      const msg = this.authStore.signup_msg;

      if (msg.code) {
        switch (msg.code) {
          case 'auth/weak-password':
            this.validationText = 'The password provided is too weak. Please choose a stronger password with at least 6 characters.';
            break;
          case 'auth/email-already-in-use':
            this.validationText = 'This email is already registered. Please sign in or use a different email to create an account.';
            break;
          case 'auth/invalid-email':
            this.validationText = 'The email address provided is not in a valid format. Please enter a valid email address.';
            break;
          case 'auth/network-request-failed':
            this.validationText = 'There was a problem with the network connection. Please check your internet connection and try again.';
            break;
          case 'auth/user-disabled':
            this.validationText = 'Your account has been disabled. Please contact support for assistance.';
            break;
          case 'auth/operation-not-allowed':
            this.validationText = 'The requested operation is not allowed. Please contact support for assistance.';
            break;
          case 'auth/too-many-requests':
            this.validationText = 'Too many requests. Please try again later.';
            break;
          default:
            this.validationText = 'An error occurred during authentication. Please try again.';
            break;
        }
        this.signing_up = false;
      } else {
        this.validationText = '';
        const user = this.authStore.user;
        await this.createUser(user);
        this.signing_up = false;
        this.$router.push("/");
      }
    },

    alertWIP() {
      alert("Work In Progress");
    },

    logUserCreds() {
      const data = {
        username: this.email,
        password: this.password,
      };
      console.log(this.email, this.password);
    },

    async createUser(userData) {
      try {
        const response = await fetch(this.create_user_prod, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        console.log(responseData);

      } catch (error) {
        console.error(error);
      }
    },




    checkPasswordValidation() {
      if (!this.validPasswordRegex.test(this.password)) {
        this.validationText =
          "Password must contain: Uppercase character, one special character, a number and must be at least 8 characters long.";
        return true;
      } else {
        this.validationText = "";
        return false;
      }
    },

    checkPasswords() {
      if (this.password == this.confirmedPassword) {
        this.validationText = "";
      } else {
        this.validationText = "Passwords don't match";
      }
    },
    validatePassword() {
      return this.validPasswordRegex.test(this.password);
    },


  },
};
</script>
<style lang=""></style>
