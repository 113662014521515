<template>
  <div>
    <div>
      <!-- <link href="./signup-page.css" rel="stylesheet" /> -->

      <div class="login-page-container">
        <div class="login-page-container1">
          <div class="login-page-container2">
            <div class="login-page-container3">
              <img alt="image" src="assets/logo.png" class="login-page-image" />
            </div>
            <div class="login-page-container4">
              <span class="login-page-text">
                <span>Welcome to</span>
                <br class="login-page-text2" />
                <span class="login-page-text3">Delight</span>
                <span class="login-page-text4">De</span>
                <span class="login-page-text5">Heaven</span>
                <span>
                  , your one-stop destination for premium dry fruits, and nuts.
                </span>
              </span>
            </div>
          </div>
          <form class="login-page-container5" @submit.prevent="login" name="login-form">
            <div class="login-page-container6">
              <label class="login-page-label">LOGIN</label>
              <input type="text" id="user_Name" required placeholder="User Name" class="login-page-textinput input"
                v-model="email" />
              <span style="display: flex; width: 100%;">
                <input :type="passwordVisible" id="Password" required placeholder="Password"
                  class="login-page-textinput1 input" v-model="password" title="Toggle Password Visibility"
                  style="width: 100%;" />
                <span class="fa fa-eye-slash eye-slash" @click="togglePasswordVisibility"
                  :style="visibilitySwitchStyle"></span>
              </span>
              <span rel="noreferrer noopener" class="login-page-link" onclick="alert('Work In Progress')">
                Forgot password..?
              </span>
              <div class="message">

                {{ response }}

              </div>
              <button type="submit" class="login-page-button button" @submit.prevent="login"
                style="display: flex; justify-content: center; align-items: center;">
                <span v-if="!logging_in">{{ login_text }}</span>
                <Spinner class="spinner" v-if="logging_in"></Spinner>
              </button>




            </div>
          </form>


        </div>
      </div>
    </div>
  </div>
</template>

<style>
.eye-slash {
  /* position: absolute;
  right: 10px;
  border: 1px solid red;
  bottom: 50px; */
  margin-left: -30px;
  margin-top: 8px;
  font-size: 20px;
  cursor: pointer;
}

.message {
  color: red;
  font-size: 15px
}

.spinner {
  position: relative;
}
</style>

<script>
import { authStore } from "@/store";
import Spinner from "@/components/Spinner.vue"
import "../../public/styles/style.css";
import "../../public/styles/index.css";


export default {


  components: {
    Spinner
  },

  data() {
    return {
      store: authStore(),
      email: "",
      password: "",
      confirmedPassword: "",
      validationText: "",
      submitDisabled: true,
      validPasswordRegex: new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
      ),
      wrongPasswordText:
        "Password must contain at least one uppercase character, one special character ",
      passwordVisible: "password",

      visibilitySwitchStyle: "color: #2c3e50;",


      login_text: "Login",
      response: '',
      logging_in: false,


      // API urls
      create_user_prod: "server/create_user.php",
      create_user_dev: "http://localhost:3000/public/server/create_user.php",

    };
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordVisible =
        this.passwordVisible === "password" ? "text" : "password";

      if (this.passwordVisible === "password") {
        this.passwordVisible === "text";
        this.visibilitySwitchStyle = "color: #2c3e50";
      } else {
        this.passwordVisible === "password";
        this.visibilitySwitchStyle = "color: white";
      }
      // alert(this.passwordVisible == "text");
      // alert(this.passwordVisible);
    },




    async signUp() {
      try {
        await this.store.signup(this.email, this.password);
        const data = this.store.user;
        await this.createUser(data);
        this.$router.push("/");
      } catch (err) {
        this.validationText = err;
      }
    },

    alertWIP() {
      alert("Work In Progress");
    },

    logUserCreds() {
      const data = {
        username: this.email,
        password: this.password,
      };
      console.log(this.email, this.password);
    },

    async createUser(data) {
      await fetch(this.create_user_prod, {
        body: JSON.stringify(data),
        method: "POST",
      })
        .then((resp) => resp.text())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
    },

    checkPasswordValidation() {
      if (!this.validPasswordRegex.test(this.password)) {
        this.validationText =
          "Password must contain: Uppercase character, one special character, a number and must be at least 8 characters long.";
        return true;
      } else {
        this.validationText = "";
        return false;
      }
    },

    checkPasswords() {
      if (this.password == this.confirmedPassword) {
        this.validationText = "";
      } else {
        this.validationText = "Passwords don't match";
      }
    },
    validatePassword() {
      return this.validPasswordRegex.test(this.password);
    },

    async login() {

      this.logging_in = true
      await this.store.login(this.email, this.password);
      const msg = this.store.login_msg;
      if (msg.code) {
        switch (msg.code) {
          case 'auth/too-many-requests':
            this.response = 'Too many login attempts! please try after sometime.'
            break;
          case 'auth/user-not-found':
            this.response = 'We can\'t seem to find the credentials you have entered!\nPlease verify them or try signing up'
            break;
          case 'auth/invalid-credential':
            this.response = 'Invalid Credentials! please check your credentials and try again'
            break;
          case 'auth/invalid-email':
            this.response = 'Invalid Email! please check your email and try again'
            break;
          case 'auth/user-disabled':
            this.response = 'Due to some illeagal activity, you account has been banned. Please contact support team for more info.'
            break;
          default:
            this.response = 'Some error occured! please try again';
            break;
        }
        this.logging_in = false
      } else {

        this.logging_in = false;
        this.$router.push("/");
      }
      // console.log(resp);
    },
  },
};
</script>
<style>
input,
input:focus {
  outline: none;
}

.container5-container {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  border-color: var(--dl-color-primary-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  background-color: var(--dl-color-primary-500);
}

.container5-container1 {
  flex: 0 0 auto;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.container5-icon {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}

.container5-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}

.container5-link {
  color: rgb(245, 244, 244);
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .container5-container {
    border-radius: var(--dl-radius-radius-radius4);
  }

  .container5-container1 {
    border-radius: var(--dl-radius-radius-radius4);
  }
}

@media (max-width: 479px) {
  .container5-container {
    z-index: 100;
    margin-top: 40px;
    border-radius: var(--dl-radius-radius-radius4);
  }

  .container5-container1 {
    z-index: 100;
    border-radius: var(--dl-radius-radius-radius4);
  }
}

.login-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* background-color: var(--dl-color-danger-700); */
  background-color: #556b2f;
}

.login-page-container1 {
  width: 881px;
  height: 600px;
  display: flex;
  max-width: auto;
  align-self: center;
  box-shadow: 5px 5px 10px 0px #020202;
  align-items: center;
  border-radius: 40px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("../../public/assets/_230a6a52-bb4c-4906-bdef-1f29b49e58cb-1100h.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.login-page-container2 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.login-page-container3 {
  width: 196px;
  height: 198px;
  display: flex;
  align-self: center;
  box-shadow: 0px 5px 10px 0px #130f0f;
  margin-top: 40px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.79);
}

.login-page-image {
  width: 200px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.login-page-container4 {
  width: 337px;
  height: 230px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 10px;
  align-items: center;
  margin-left: 0px;
  padding-left: 4px;
  flex-direction: column;
  justify-content: center;
}

.login-page-text {
  color: var(--dl-color-gray-white);
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}

.login-page-text3 {
  color: var(--dl-color-danger-500);
}

.login-page-text4 {
  color: var(--dl-color-danger-500);
}

.login-page-text5 {
  color: var(--dl-color-danger-500);
}

.login-page-container5 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(82, 66, 66, 0.19);
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.login-page-container6 {
  width: 326px;
  height: 434px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 40px;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-direction: column;
}

.login-page-label {
  color: var(--dl-color-gray-white);
  font-size: 50px;
  align-self: center;
  font-style: normal;
  margin-top: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.login-page-textinput {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  margin-top: 10px;
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom-width: 1px;
}

.login-page-textinput1 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom-width: 1px;
}

.login-page-link {
  color: var(--dl-color-gray-white);
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-page-button {
  color: #fdfbfb;
  width: 100%;
  font-size: 25px;
  align-self: center;
  font-style: normal;
  margin-top: 20px;
  font-weight: 700;
  border-width: 0px;
  border-radius: 40px;
  margin-bottom: 20px;
  background-image: linear-gradient(90deg,
      rgb(189, 195, 199) 0%,
      rgb(74, 153, 232) 100%);
}

.login-page-link1 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

@media (max-width: 991px) {
  .login-page-container {
    justify-content: center;
  }

  .login-page-container1 {
    width: 844px;
    position: static;
  }

  .login-page-container4 {
    width: 343px;
    height: 203px;
    margin-top: 25px;
  }

  .login-page-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
  }

  .login-page-text2 {
    color: var(--dl-color-danger-500);
  }

  .login-page-text3 {
    color: var(--dl-color-danger-500);
  }

  .login-page-text4 {
    color: var(--dl-color-danger-500);
  }

  .login-page-text5 {
    color: var(--dl-color-danger-500);
  }

  .login-page-container6 {
    width: 305px;
  }
}

@media (max-width: 767px) {
  .login-page-container {
    justify-content: center;
  }

  .login-page-container1 {
    width: 641px;
    height: 488px;
  }

  .login-page-container2 {
    width: 296px;
    position: relative;
  }

  .login-page-container3 {
    top: 0px;
    left: 63px;
    bottom: 0px;
    margin: auto;
    position: absolute;
  }

  .login-page-container4 {
    width: 322px;
  }

  .login-page-text {
    display: none;
  }

  .login-page-container5 {
    width: 346px;
    margin-right: 0px;
  }

  .login-page-container6 {
    width: 225px;
  }
}

@media (max-width: 479px) {
  .login-page-container1 {
    width: 100%;
    height: 1002px;
    position: static;
    border-radius: 0px;
    padding-right: 0px;
  }

  .login-page-container2 {
    display: none;
  }

  .login-page-container5 {
    width: 100%;
    border-radius: 0px;
    background-color: rgba(82, 66, 66, 0.35);
  }

  .login-page-container6 {
    width: 323px;
    height: 430px;
  }
}
</style>
