<template>
  <h1 v-if="errorText == null">
    Hang on while we redirect you to the payment page.
  </h1>
  <h1 v-else>{{ errorText }}</h1>
</template>

<script>
import { productStore } from "@/store/productStore";
import axios from "axios";

export default {
  data() {
    return {
      paymentUrl: "https://delightheaven.in/node/",
      //   paymentUrl: "http://localhost:3000",

      errorText: null,
    };
  },

  methods: {
    async initiatePayment() {
      const amount = productStore().selectedProducts.price[0]["price"];
      // try to initiate payment
      try {
        const response = await axios.post(this.paymentUrl, { amount: amount });

        const redirectUrl =
          response.data.data.instrumentResponse.redirectInfo.url;
        window.location.href = redirectUrl;
      } catch (e) {
        this.errorText = "Payment Initiation Failed!";
      }
    },
  },

  mounted() {
    const script = document.createElement("script");
    script.setAttribute("data-section-id", "navbar");
    script.src = "https://unpkg.com/@teleporthq/teleport-custom-scripts";
    script.async = true;
    document.head.appendChild(script);

    this.initiatePayment();
  },
};
</script>
