<template>
  {{ responseData }}

  <br />
  <br />

  Txn Id: {{ tId }}
</template>

<script>
import axios from "axios";
import sha256 from "sha256";

export default {
  data() {
    return {
      tId: null,
      responseData: null,
    };
  },

  mounted() {
    const PHONE_PE_HOST_URL = "https://api-preprod.phonepe.com/apis/hermes";
    // const PHONE_PE_HOST_URL = "https://api.phonepe.com/apis/hermes";
    const MERCHANT_ID = "PGTESTPAYUAT86";
    // const MERCHANT_ID = "DELIGHTDEONLINE";
    const SALT_INDEX = 1;
    const SALT_KEY = "96434309-7796-489d-8924-ab56988a6076";
    // const SALT_KEY = "53ae236d-5e91-4f85-a3b9-74e088cfadb7";
    this.tId = this.$route.params.merchantTransactionId;
    var merchantTransactionId = this.tId;
    if (merchantTransactionId) {
      //   const axios = require("axios")
      const xVerify =
        sha256(
          `/pg/v1/status/${MERCHANT_ID}/${merchantTransactionId}` + SALT_KEY
        ) +
        "###" +
        SALT_INDEX;
      const options = {
        method: "get",
        url: `${PHONE_PE_HOST_URL}/pg/v1/status/${MERCHANT_ID}/${merchantTransactionId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-MERCHANT-ID": merchantTransactionId,
          "X-VERIFY": xVerify,
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log(response);
          if (response.data.code == "PAYMENT_SUCCESS") {
            // redirect the user to success page
            console.log("payment success");
            // this.responseData = "Payment Successful";
          } else if (response.data.code == "PAYMENT_ERROR") {
            // console.log(response.data);
            // redirect the user to error page
            // this.responseData = "Payment Failed";
            console.log("payment error");
          } else {
            // pending page
            // this.responseData = "Payment Pending";
          }
          console.log(response.data);
        })
        .catch(function (error) {
          // this.responseData = error;
          console.error(error);
        });
    }
  },
};
</script>
