<template>
  <div class="_home-top-categories">
    <h1 class="home-text02">
      <h1 class="home-text03">TOP CATEGORIES</h1>
      <br class="home-text04" />
    </h1>
    <div class="home-container06">
      <!-- class="feature-card1-feature-card categ-card feature-card1-root-class-name" -->
      <div class="categ-card" v-for="category in categories">
        <img :src="category.image" alt="image" class="feature-card1-image" />
        <h2 class="feature-card1-heading">
          <span>{{ category.title }}</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<style scoped>
._home-top-categories {
  display: flex;
  padding: 8rem 1.5rem;
  flex-direction: column;
  background-color: #faf4b7;
}

.home-text03 {
  font-size: 40px;
  margin-top: -2rem;
  margin-bottom: 1rem;
}

.categ-card {
  width: 230px;
  background-color: #ffbd59;
  /* background-color: #5562bf; */
  background-color: #556b2f;
  color: white;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* box-shadow: 2px 2px 5px 3px #fdc4c4; */
  padding: 30px 0;
  margin-top: 1rem;
}

.feature-card1-image {
  width: 180px;
  height: 180px;
  border-radius: 8px;
}

.home-container06 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
}

@media screen and (max-width: 800px) {
  .categ-card {
    width: 150px;
    height: 200px;
  }

  .feature-card1-image {
    /* scale: 0.5; */
    width: 100px;
    height: 100px;
  }

  .feature-card1-heading {
    font-size: 18px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      categories: [
        {
          title: "Dry Fruits",
          image: "assets/dry_fruits.jpeg",
        },
        {
          title: "Dried Fruits",
          image: "assets/dried_fruits.jpeg",
        },
        {
          title: "Kernels",
          image: "assets/almonds.jpeg",
        },
        {
          title: "Saffron",
          image: "assets/saffron.jpeg",
        },
      ],
    };
  },
};
</script>
