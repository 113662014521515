<template>

    <div>
        <div class="feedback-container">
            <h2>Feedbacks</h2>
            <div class="feedback-item" v-for="feedback in feedbacks">
                <h3>{{ feedback.subject }}</h3>
                <p><strong>Name:</strong> {{ feedback.name }}</p>
                <p><strong>Email:</strong> {{ feedback.email }}</p>
                <p><strong>Message:</strong> {{ feedback.message }}</p>
            </div>
        </div>
    </div>
</template>

<style>
.feedback-container {
    max-width: 800px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    text-align: start;
}

.feedback-item {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

.feedback-item h3 {
    margin-bottom: 5px;
}

.feedback-item p {
    margin: 5px 0;
}
</style>
<script>

export default {
    data() {
        return {
            feedbacks: [],
            feedback_url_prod: 'php/get_feedback.php',
            feedback_url_dev: 'http://localhost:3000/get_feedback.php',
        }
    },
    created() {
        fetch(this.feedback_url_prod)
            .then(resp => resp.json())
            .then(data => {
                this.feedbacks = data;
            })
            .catch(error => {
                console.error(error)
            })
    }
}


function getData() {
    fetch('http://localhost:3000/get_feedback.php')
        .then(resp => resp.json())
        .then(data => {
            data.forEach(
                feedback => {
                    console.log(feedback.name);
                    const feedbackElement = document.createElement('div');
                    feedbackElement.innerHTML = `
                                <p>Name: ${feedback.name}</p>
                                <p>Email: ${feedback.email}</p>
                                <p>Subject: ${feedback.subject}</p>
                                <p>Message: ${feedback.message}</p>
                                <hr>
                            `;
                    document.getElementById('feedbackContainer').appendChild(feedbackElement);

                }
            )
        })
        .catch(error => {
            document.getElementById('feedbackContainer').innerHTML = error
        })
}
</script>
