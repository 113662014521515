<template>
  <div class="card-container-one" v-if="product.title">
    <div class="product-card-body">
          <img :src="product.images" class="product-image"/>
<!--      <img :src="imgUrl + JSON.parse(product.paths)[0]" class="product-image" />-->
    </div>
    <div class="card-footer-">
      <div class="card-desc">
        <span class="card-title">{{ product.title }}</span>
        <span class="quantity"> From ₹{{ product.price[250] }}</span>
      </div>
      <div class="card-btns">
        <button class="btn-view" @click="view_item(product.title)">
          View Item
        </button>
        <button class="btn-cart" @click="addToCart(product)">
          Add to cart
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <h1>Product not found!</h1>
  </div>
</template>

<script>
import { addToCart } from "@/cart/index";
import { authStore } from "@/store/index";
export default {
  props: ["product"],
  data() {
    return {
      imgUrl: "https://delightheaven.in/../Old/Upload Data/",
      authStore: authStore(),
    };
  },

  // Methods
  methods: {
    // View product

    view_item(product_name) {
      // console.log(product_name);
      this.$router.push({ name: "product", query: { id: product_name } });
    },

    // Add product to cart
    async addToCart(product) {

      const result = await addToCart(product, this.authStore, this.isLoggedIn);

      if (result) {
        this.$moshaToast("Item added to cart", {
          type: "success",
          timeout: 1500,
        });
      } else {
        this.$moshaToast("Some error occured", {
          type: "danger",
          showIcon: true,
          timeout: 1500,
        });
      }
    },
  },

  computed: {
    isLoggedIn() {
      return this.authStore.user ? true : false;
    },
  },
};
</script>

<style scoped>
.card-container-one {
  width: 230px;
  height: 310px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  border-radius: 8px;
  flex-direction: column;
  background-color: #556b2f;
  color: white;
}

.card-title {
  font-size: 13px;
  margin-top: 5px;
  text-align: left;
  width: 100%;
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.product-card-body {
  width: 100%;
  display: inline-flex;
}

.product-image {
  object-fit: cover;
  width: 190px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 12px;
  margin-top: 1rem;
}

.card-desc {
  display: flex;
  flex-direction: column;
  color: #cdc;
  font-weight: 700;
  text-align: left;
  margin: .2rem;
  gap: 0.2rem;
}

.quantity {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
}

.price {
  font-size: 16px;
}

.card-footer- {
  display: inline-flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-top: 5px;
  justify-content: flex-start;
  padding: 0 0.5rem;
  border: none;
}

.card-btns {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  /* border: 1px solid white; */
  /* position: relative;
          bottom: 5px; */
}

.card-btns *:hover {
  scale: 1.02;
  transition: all 0.2s;
}

.btn-view,
.btn-cart {
  box-shadow: 1px 1px 3px #111;
  cursor: pointer;
  width: 100px;
  height: 25px;
  font-size: 11px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  border: none;
}

.btn-view {
  color: black;
  background: white;
}

.btn-cart {
  background: black;
  color: white;
}

* {
  border: none;
}

@media screen and (max-width: 767px) {
  .card-container-one {
    width: 170px;
    height: 220px;
    padding: 0 0.5rem;
  }

  .product-image {
    height: 120px;
    aspect-ratio: 1/0.8;
  }


  .card-title {
    font-size: 10px;
  }

  .card-desc {
    font-size: 0.5rem;
  }


  .quantity {
    display: inline-block;
    font-size: 0.55rem;
    line-height: 1;
  }



  .card-footer- {
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 5px;
  }

  .card-btns {
    gap: 0.3rem;
  }

  .btn-view,
  .btn-cart {
    height: 1.3rem;
    font-size: 7px;
  }

}
</style>
