<template>
    <div>
        <h4>Your Details</h4>
        <p style="text-align: start;margin: auto;">
            Firstname: {{ response.firstname }} <br>
            Lastname: {{ response.lastname }}<br>
            Phone: {{ response.phone }}<br>
            Street: {{ response.userStreet }}<br>
            District: {{ response.userDistrict }}<br>
            State: {{ response.userState }}<br>
            Pincode: {{ response.userPincode }}<br>
        </p>
    </div>
</template>


<script>

import { authStore } from '@/store';
import axios from 'axios';


export default {
    data() {
        return {
            authStore: authStore(),
            api_url: 'php/get_user.php',
            response: {},
        }
    },

    computed: {
        username() {
            return this.authStore.user.displayName ? this.authStore.user.displayName != null : this.authStore.user.email;
        },
        email() {
            return this.authStore.user.email;
        }
    },

    methods: {
        fetch_user_data() {
            let data = {
                email: this.email
            }
            axios.post(
                this.api_url, data
            ).then(resp => {
                if (resp.data.success) {
                    this.response = resp.data.message.user;
                } else {
                    this.respinse = "failed to get data!";
                }
            }).catch(error => {
                this.response = error
            })
        }
    },

    mounted() {
        this.fetch_user_data()
    }
}

</script>