<template>
    <div class="full-size-container">
        <div class="login-container">
            <h1 class="heading">Delight de heaven</h1>
            <div class="message">
                <p class="message-text">Welcome! Please log in to access this page.</p>
                <p class="message-text">If you're new here, we'd love for you to join our family by signing up!</p>
            </div>
            <div class="options">
                <router-link to="/login" class="btn">Log In</router-link>
                <router-link to="/register" class="btn">Sign Up</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { authStore } from '@/store';

export default {
    name: 'LoginPagePrompt',
    data() {
        return {
            authStore: authStore()
        }
    },
    methods: {
        login() {
            if (this.isLoggedIn) {
                this.$router.push({ name: "payment-page" });
                console.log(authStore().user);
            }
        }
    },
    mounted() {
        // navigate to login page if not logged in
        this.login();
    },
    computed: {
        isLoggedIn() {
            return this.authStore.user ? true : false;
        },
    },
    watch: {
        isLoggedIn(val) {
            if (val) {
                // redirect to where you came from
                this.$router.push(router.currentRoute._rawValue.query.redirect)
            }
        }
    }
}
</script>

<style scoped>
.full-size-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #7eb5e7;
    /* Soft Blue background */
}

.login-container {
    padding: 50px;
    /* Increased padding */
    background-color: #fff;
    /* Creamy Yellow background */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #555;
    /* Dark gray text */
    width: 80%;
    /* Adjusted width */
    max-width: 600px;
    /* Max width */
    height: auto;
    /* Auto height */
    max-height: 70vh;
    /* Max height */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.heading {
    font-size: 2.5rem;
    /* Larger font size for the heading */
    margin-bottom: 20px;
    /* Increased margin below the heading */
}

.message-text {
    font-size: 1.2rem;
    /* Reduced font size */
    margin-bottom: 20px;
    font-weight: 500;
    /* Medium font weight */
    color: #555;
}

.options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    /* Increased space between options */
    margin-top: 20px;
    /* Increased margin from message */
}

.btn {
    width: 150px;
    background-color: #f3c646;
    /* Soft Yellow button */
    color: #333;
    /* Dark text for contrast */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #f2b603;
    /* Darker shade of yellow when hovered */
}

@media screen and (max-width: 768px) {
    .login-container {
        width: 90%;
        /* Adjusted width for smaller screens */
    }

    .btn {
        width: 120px;
        /* Reduced width for smaller screens */
        padding: 8px 16px;
        /* Reduced padding for smaller screens */
    }
}
</style>