<template>

    <ul>
        <li v-for="todo in todos" :key="todo.id">
        <span>{{ todo }}</span>
    </li>
    </ul>

</template>


<script setup>
// import {useFireStore} from 'vuefire'
// const db = useFireStore()



import {collection } from 'firebase/firestore';
import {useCollection} from 'vuefire';

const todos = useCollection(collection(db, 'products'));

</script>