<template>
  <div class="payment-page2-container">
    <form class="payment-page2-form">
      <div class="payment-page2-container01">
        <label class="payment-page2-text">CHECKOUT</label>
      </div>
      <div
        class="payment-page2-container02"
        @click="go_back"
        style="cursor: pointer"
      >
        <svg viewBox="0 0 1024 1024" class="payment-page2-icon">
          <path
            d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"
          ></path>
        </svg>
        <div @click="go_back" style="cursor: pointer">
          <span>Back to shoppings</span>
          <br />
        </div>
      </div>
      <div class="payment-page2-checkoutprocess">
        <div class="payment-page2-container03">
          <label class="payment-page2-text03">
            <span>STEP</span>
            <br />
            <span>1</span>
          </label>
        </div>
        <div class="payment-page2-container04">
          <label class="payment-page2-text07">
            <span>STEP</span>
            <br />
            <span>2</span>
            <br />
          </label>
        </div>
        <div class="payment-page2-container05">
          <label class="payment-page2-text12">
            <span>STEP</span>
            <br />
            <span>3</span>
            <br />
          </label>
        </div>
      </div>
      <div class="payment-page2-container06">
        <div class="payment-page2-container07">
          <img
            alt="image"
            src="/assets/public/select-amico.svg"
            class="payment-page2-image"
          />
        </div>
        <div class="payment-page2-container08">
          <div class="payment-page2-container09">
            <label class="payment-page2-text17"> Select Payment Method </label>
          </div>
          <div class="payment-page2-container10">
            <div class="payment-page2-container11" @click="to_card_page">
              <img
                alt="image"
                src="/assets/public/credit-card-refund-svgrepo-com.svg"
                class="payment-page2-image1"
              />
              <label class="payment-page2-text18">CARD</label>
            </div>
            <div class="payment-page2-container12" @click="to_upi_page">
              <img
                alt="image"
                src="/assets/public/upi.svg"
                class="payment-page2-image2"
              />
              <label class="payment-page2-text19">UPI PAYMENT</label>
            </div>
          </div>
          <img
            alt="image"
            src="/assets/public/external/cash%20payment-bro.svg"
            class="payment-page2-image5"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import { productStore } from "@/store/productStore";
import axios from "axios";
import sha256 from "sha256";
import { Buffer } from "buffer";

// const PHONE_PE_HOST_URL = "https://api-preprod.phonepe.com/apis/hermes";
// const MERCHANT_ID = "PGTESTPAYUAT86";
// const SALT_INDEX = 1;
// const SALT_KEY = "96434309-7796-489d-8924-ab56988a6076";

window.Buffer = Buffer;
export default {
  methods: {
    to_upi_page() {
      this.$router.push("/upi-payment");
    },
    to_card_page() {
      this.$router.push("/card-payment");
    },
    go_back() {
      // this.$router.back()
      // this.$router.go(-1) // can be used also
      router.go(-1);
      this.$router.push("/card-payment");
    },

    async initiatePayment() {
      const amount = productStore().selectedProducts.price[0]["price"];
      try {
        const response = await axios.post("https://delightheaven.in/node/", {
          amount: amount,
        });

        const redirectUrl =
          response.data.data.instrumentResponse.redirectInfo.url;
        window.location.href = redirectUrl;
      } catch (e) {
        console.log("Payment initiation failed: ", e);
      }
    },
  },

  mounted() {
    const script = document.createElement("script");
    script.setAttribute("data-section-id", "navbar");
    script.src = "https://unpkg.com/@teleporthq/teleport-custom-scripts";
    script.async = true;
    document.head.appendChild(script);

    // window.location.replace("http://localhost:4444/pay");
    this.initiatePayment();
  },
};
</script>

<style scoped>
.payment-page2-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.payment-page2-form {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
}

.payment-page2-container01 {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page2-text {
  font-size: 25px;
  align-self: center;
}

.payment-page2-container02 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
}

.payment-page2-icon {
  width: 24px;
  height: 24px;
}

.payment-page2-checkoutprocess {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  height: 116px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}

.payment-page2-container03 {
  flex: 0 0 auto;
  width: 122px;
  height: 122px;
  /* height: 100%; */
  display: flex;
  box-shadow: 5px 5px 10px 0px #d8bebe;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #e6eeb8;
}

.payment-page2-text03 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page2-container04 {
  flex: 0 0 auto;
  width: 122px;
  height: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d8bebe;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #e6eeb8;
}

.payment-page2-text07 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page2-container05 {
  flex: 0 0 auto;
  width: 122px;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
}

.payment-page2-text12 {
  font-size: 25px;
  align-self: center;
  text-align: center;
}

.payment-page2-container06 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 924px;
  display: flex;
  align-items: flex-start;
}

.payment-page2-container07 {
  width: 50%;
  height: 924px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}

.payment-page2-image {
  width: 571px;
  height: 732px;
  object-fit: cover;
}

.payment-page2-container08 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.payment-page2-container09 {
  flex: 0 0 auto;
  width: auto;
  height: 34px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page2-text17 {
  font-size: 25px;
  align-self: center;
}

.payment-page2-container10 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 133px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}

.payment-page2-container11 {
  flex: 0 0 auto;
  width: 175px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  transition: 0.3s;
  flex-direction: column;
  justify-content: flex-end;
}

.payment-page2-container11:hover {
  box-shadow: 5px 5px 10px 0px #978989;
  border-radius: 50px;
  border: none;
}

.payment-page2-image1 {
  width: 101px;
  height: 104px;
  align-self: center;
  object-fit: cover;
}

.payment-page2-text18 {
  align-self: center;
}

.payment-page2-container12 {
  flex: 0 0 auto;
  width: 178px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
}

.payment-page2-container12:hover {
  box-shadow: 5px 5px 10px 0px #978787;
  border-radius: 50px;
}

.payment-page2-image2 {
  width: 135px;
  height: 58px;
  align-self: center;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page2-text19 {
  align-self: center;
}

.payment-page2-container13 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 133px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}

.payment-page2-container14 {
  flex: 0 0 auto;
  width: 175px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-end;
}

.payment-page2-container14:hover {
  box-shadow: 5px 5px 10px 0px #978989;
  border-radius: 50px;
}

.payment-page2-image3 {
  width: 75px;
  height: 83px;
  align-self: center;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page2-text20 {
  align-self: center;
}

.payment-page2-container15 {
  flex: 0 0 auto;
  width: 178px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-end;
  padding-top: 16px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
}

.payment-page2-container15:hover {
  box-shadow: 5px 5px 10px 0px #9d9797;
  border-radius: 50px;
}

.payment-page2-image4 {
  width: 67px;
  height: 93px;
  align-self: center;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}

.payment-page2-text21 {
  align-self: center;
  text-align: center;
}

.payment-page2-image5 {
  width: 100%;
  height: 590px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .payment-page2-container02 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .payment-page2-container06 {
    justify-content: center;
  }

  .payment-page2-container07 {
    display: none;
  }

  .payment-page2-image {
    width: 100%;
    height: 480px;
  }

  .payment-page2-container08 {
    width: 558px;
  }
}

@media (max-width: 479px) {
  .payment-page2-container02 {
    width: auto;
    align-items: center;
    margin-left: 00px;
    margin-right: 00px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .payment-page2-checkoutprocess {
    gap: var(--dl-space-space-unit);
  }

  .payment-page2-container08 {
    height: 893px;
  }
}
</style>
