import { defineStore } from "pinia";
// import { authStore } from ".";

export const productStore = defineStore("products", {
    setup: () => {
        selectedProducts: []
        // totalPrice: []
    },
    actions: {
        setItems(products, price) {
            this.selectedProducts = {
                products: products,
                price: price
            }
            // products
            // this.totalPrice = price
        },

    }
});