import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { getStorage} from "firebase/storage";
// import store from "./store";

// firebase imports
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";

// pinia imports
import { createPinia } from "pinia";

// Confirm Dialog imports
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import "primevue/resources/themes/nova-vue/theme.css";

// prime icons
// import "primeicons/primeicons.css";

// Mosha dialog components
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

// Css imports
// import "../public/styles/index.css";
import "../public/styles/login-page.css";
// import "../public/styles/style.css";
import "../public/styles/signup-style.css";
import "../public/styles/cart-style.css";
import { collection, getFirestore } from "firebase/firestore";
import { VueFire } from "vuefire";

// pinia stuff
const pinia = createPinia();

// firebase stuff

const firebaseConfig = {
  apiKey: "AIzaSyBfBi2HGkGNqduMQwXzQ8QciVgyyxSiijw",
  authDomain: "rising-daylight-325319.firebaseapp.com",
  projectId: "rising-daylight-325319",
  storageBucket: "rising-daylight-325319.appspot.com",
  messagingSenderId: "700566060214",
  appId: "1:700566060214:web:2729822418e0164b68fa17",
};

// Initialize firebase
export const firebaseApp = initializeApp(firebaseConfig);

// used for firestore refs
const db = getFirestore(firebaseApp);

// here we can export reusable database references
export const prodRef = collection(db, "products");

// initialize firebase auth and google auth provider
const auth = getAuth();
const provider = new GoogleAuthProvider();

const storage = getStorage(firebaseApp);
export { storage, db,  auth, provider };

createApp(App)
  .use(pinia)
  .use(router)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .use(moshaToast)
  .use(VueFire,{firebaseApp})
  .mount("#app");
