<template>
    <div class="container">
        <h1>User Account</h1>
        <div class="user-info">
            <!-- <img src="profile.jpg" alt="Profile Picture">
            <span class="material-symbols-outlined">
account_circle
</span> -->
            <div class="user-details">
                <p><strong>Firstname:</strong> {{ user.firstname }}</p>
                <p><strong>Lastname:</strong> {{ user.lastname }}</p>
                <p><strong>Email:</strong> {{ email }}</p>
                <p><strong>Phone:</strong> {{ user.phone }}</p>
                <p><strong>Address:</strong> {{ user.address }}</p>
            </div>
            <div class="user-actions">
                <button @click="toggleEditForm" class="button edit-button">Edit Profile</button>
                <button class="button">Change Password</button>
                <button class="button" @click="logout">Logout</button>
            </div>
        </div>
        <div v-show="showEditForm" class="edit-form">
            <form id="editForm" @submit.prevent="update_user_data">
                <h2>Edit Profile</h2>
                <label for="firstname">Firstname:</label>
                <input type="text" id="firstname" name="firstname" v-model="firstname">
                <label for="lastname">Lastname:</label>
                <input type="text" id="lastname" name="lastname" v-model="lastname">
                <!-- <label for="phone">Phone:</label> -->
                <!-- <input type="text" id="phone" name="phone" v-model="phone"> -->
                <label for="address">Address:</label>
                <input type="text" id="address" name="address" v-model="address">
                <div class="action-btns">

                    <button type=" submit" class="button save-button" @submit.prevent="update_user_data">Save
                        Changes</button>
                    <button type="button" @click="cancelEdit" class="button cancel-button">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import { authStore } from '@/store';
import axios from 'axios';

export default {
    data() {
        return {
            showEditForm: false,
            user: {
                firstname: '',
                lastname: '',
                // email: '',
                phone: '',
                address: ''
            },

            firstname: '',
            lastname: '',
            phone: '',
            address: '',


            user_details_api: 'php/get_user.php',
            edit_user_api: 'php/edit_user.php',
        };
    },
    mounted() {
        this.get_user_data()
    },
    methods: {
        toggleEditForm() {
            this.showEditForm = !this.showEditForm;
        },
        cancelEdit() {
            this.showEditForm = false;
        },

        async get_user_data() {
            let data = {
                email: this.email
            }
            await axios.post(
                this.user_details_api, data
            ).then(resp => {
                if (resp.data.success) {
                    let user = resp.data.message.user;
                    this.firstname = this.user.firstname = user.firstname;
                    this.lastname = this.user.lastname = user.lastname;
                    this.phone = this.user.phone = user.phone;
                    this.address = this.user.address = user.userStreet;
                } else {
                    this.$router.push("/user-details");
                }
            }).catch(error => { this.user.firstname = error; console.log(error) })
        },

        update_user_data() {
            let data = {
                firstname: this.firstname,
                lastname: this.lastname,
                address: this.address,
                email: this.email,
            }

            axios.post(
                this.edit_user_api,
                data
            ).then(resp => {
                console.log(resp.data.success)
                console.log(resp.data.message)
            }).catch(error => {
                console.error(error)
            })

            this.get_user_data()
            this.toggleEditForm()
        },

        clearForm() {
            this.firstname = '';
            this.lastname = '';
            this.email = '';
            this.phone = '';
            this.address = '';
        },
        logout() {
            authStore().signout();
            this.$router.push('/');
        }
    },
    computed: {
        email() {
            return authStore().user.email;
        }
    }
};
</script>

<!-- KALAROOS -->

<style scoped>
.container {
    max-width: 80%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    flex-direction: column;
    margin: 5rem auto;
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-details {
    text-align: start;
}

.user-actions {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.user-actions * {
    width: 80%;
    margin: 2% auto;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    /* margin: 0 10px; */
}

.button:hover {
    background-color: #0056b3;
}

.edit-form {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
    text-align: start;
}

.edit-form label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.edit-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.edit-form .save-button,
.edit-form .cancel-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    /* margin: 0 10px; */
    margin: 1% 10px;
}

.edit-form .cancel-button {
    background-color: #dc3545;
}

.edit-form .save-button:hover,
.edit-form .cancel-button:hover {
    background-color: #218838;
}

.action-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}


@media screen and (min-width: 768px) {
    .user-info {
        flex-direction: row;
        align-items: center;
    }

    .user-details {
        flex-grow: 1;
        max-width: 300px;
    }

    .user-actions {
        text-align: end;
    }

    .user-actions * {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 80%;
    }
}
</style>
