<template>


  <div v-show="homePage">

    <nav class="topnav">
      <!-- Logo -->
      <div class="logo">
        <div class="logo">
          <img alt="logo" src="/assets/logo_.png" class="logo-img"  />
        </div>
      </div>

      <!-- Search -->
      <div class="search">
        <input type="search" class="search-bar" @keyup="searchItems" v-model="searchQuery"
          placeholder="search...&#x1F50D">
      </div>


      <!-- Menu -->
      <div class="ham-menu">
        <span class="material-symbols-outlined" @click="toggleResponsiveNav">
          menu
        </span>
      </div>


    </nav>

    <nav class="sidenav" :style="{ width: width }">
      <div class=" logo">
        <span class="material-symbols-outlined close-icon" @click="toggleResponsiveNav">
          close
        </span>
      </div>


      <!-- Links -->

      <div class="options">
        <div class="links">
          <li v-for="link in navLinks" :key="link.name" class="nav-links">
            <router-link :to="link.to" @click="toggleResponsiveNav">
              {{ link.label }}
            </router-link>
          </li>
        </div>
      </div>


      <!-- Search -->
      <!-- <div class="navbar-search">
              <input type="search" @keyup="searchItems" v-model="searchQuery" placeholder="search...&#x1F50D">
          </div> -->



      <div class="profile">
        <div class="acc-btns" v-if="!isLoggedIn">
          <!-- LOGIN BUTTON -->
          <button class="login-btn" @click="$router.push('/login')">
            Login
          </button>

          <!-- SIGNUP BUTTON -->
          <button class="signup-btn" @click="$router.push('/register')">
            Register
          </button>
        </div>

        <div class="user-info" v-else>
          <router-link :to="{ name: 'profile' }" @click="toggleResponsiveNav">
            <span class="material-symbols-outlined" style="user-select: none;">
              account_circle
            </span> Account
          </router-link>
          <router-link :to="{ name: 'cart' }" @click="toggleResponsiveNav">
            <span class="material-symbols-outlined">
              shopping_cart
            </span>
            Cart
          </router-link>
        </div>

      </div>


    </nav>

  </div>

</template>


<script>

import { authStore } from '@/store'
import { cartStore } from '@/store/cartStore';

export default {
  data() {
    return {
      searchQuery: '',
      width: "0rem",
      navLinks: [
        { name: "home", to: { name: "home" }, label: "Home" },
        { name: "products", to: { name: "products" }, label: "Products" },
        { name: "contact", to: { name: "contact" }, label: "Contact" },
        { name: "faq", to: { name: "faq" }, label: "FAQ" }
      ],

      authStore: authStore(),
      cartStore: cartStore(),

      searh_url: 'php/search_product.php'
    }
  },


  methods: {
    toggleResponsiveNav() {
      this.width = (this.width == '0rem') ? '20rem' : '0rem';
    },

    redirectToPage(page) {
      this.toggleResponsiveNav();
      this.$router.push({ name: page });
    },

    signout() {
      this.authStore.signout();
      this.redirectToPage()
    },

    searchItems() {
      this.cartStore.searchQuery = this.searchQuery;
      const data = {
        search_query: this.searchQuery
      }
      this.redirectToPage('products');


      fetch(this.search_url, {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(resp => resp.text())
        .then(data => {
          this.cartStore.searchItems = data;
        })
        .catch(error => {
          console.error(`[-] HTTP ERROR: ${error}`)
        })
    },
  },



  computed: {
    homePage() {
      return !["/register", '/product', '/login', '/login-prompt'].includes(this.$route.path)
    },

    isLoggedIn() {
      return this.authStore.user ? true : false;
    },

    username() {
      return this.authStore.user.displayName ? this.authStore.user.displayName : this.userEmail
    },
    userEmail() {
      return this.isLoggedIn ? this.authStore.user.email : "Loading...";
    },
  },

  watch: {
    searchQuery: function (val) {
      this.cartStore.search_query = val;
    }
  }
}


</script>




<style scoped>
/* topnav */

.topnav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  background: #ffbd59;
  height: 120px;
  border-bottom: 4px solid rgba(255, 255, 255, 0.4);
}


.profile {
  margin-top: 1rem;
}

li {
  text-align: left;
  padding-left: 1rem;
  margin-top: 0.4rem;
  list-style: none;
}

.nav-links .router-link-exact-active {
  color: #556b2f;
  padding: 2px 10px;
  border-radius: 14px;
  background-color: #556b2f;
  color: #f5f5f5;
  /* margin-top: 1rem 0; */
}


.material-symbols-outlined {
  color: #111;
  cursor: pointer;
}

.search-bar {
  border: none;
  margin-top: 1rem;
  margin-right: 2rem;
  height: 2rem;
  border-radius: 8px;
  padding: 0 0.7rem;
  display: none
}


.close-icon {
  display: block;
  /* border: 1px solid; */
  text-align: end;
  padding: 0 2rem;
}

.topnav .logo .logo-img {
  height: 12.5rem;
  margin-left: 0.4rem
}

.sidenav .logo-img {
  aspect-ratio: 3/2.2;
}

.ham-menu {
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  cursor: pointer;
}


.login-btn,
.signup-btn {
  border: none;
  color: white;
  border-radius: 42px;
  padding: 5px 3px;
  width: 80px;
  /* letter-spacing: 1px; */
  font-family: "Ubuntu Mono", monospace;
  font-size: 12px;
  height: 30px;
}

.login-btn {
  background-color: transparent;
  /* border: 1px solid #291477ff; */
  /* border: 1px solid rgb(49, 3, 3); */
  border: 1px solid #556b2f;
  color: black;
}

.signup-btn {
  /* background-color: #291477ff; */
  /* background: rgb(49, 3, 3); */
  background-color: #556b2f;
}


.profile .acc-btns {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.5rem;
  align-items: center;

  /* border: 1px solid; */
}


.acc-btns button {
  width: 80%;
}


.profile .user-info {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}


.profile .user-info a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.7rem;
  gap: 0.5rem;
}
</style>


<!-- sidenav -->
<style scoped>
.sidenav {
  width: calc(20rem);
  position: fixed;
  height: 100%;
  box-shadow: 0px 3px 5px grey;
  background: white;
  z-index: 1;
  overflow-x: hidden;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  padding: 2rem 0;
}

.logo-img {
  width: 100%;
}
</style>

<style scoped>
/* Mobile */
@media (max-width: 767px) {
  .sidenav {
    width: 0;
    overflow-x: hidden;
    transition: 0.7s all ease-in-out
  }

  .topnav {
    display: flex;
  }

}
</style>