import { defineStore } from "pinia";
import { authStore } from "./index";
import axios from "axios";

export const cartStore = defineStore("user_cart", {
  setup: () => ({
    selectedItem: null,
    search_query: null,
    searchedItems: [],
    response: {},
  }),

  actions: {
    setItem(item) {
      this.selectedItem = item;
    },
    get_items() {
      // TODO: GET ALL ITEMS FROM CART(FOR)
    },
    increaseCount() {
      this.selectedItem.quantity++;
    },
    decreaseCount() {
      this.selectedItem.quantity--;
    },

    // Add the add to cart functionality
    addToCart(product) {

      if (!this.isLoggedIn) {
        this.response = {
          'success': false,
          'message': 'Invalid user',
          'error_code': 'ERR_INV_USR'
        }
        return;
      }

      const data = {
        code: product.code,
        user_id: authStore().user.id,
        product_id: product.id,
        product_price: product.price,
      };

      axios.post(
        this.cartAPI,
        data
      ).then(resp => {
        if (!resp.ok) {
          this.response = {
            'success': false,
            'message': 'Failed to fetch',
            'error_code': 'ERR_FETCH_FAIL'
          };
          return;
        }
        resp.data.text()
      }).catch(error => console.error(error))


      // if (this.isLoggedIn) {
      //   const data = {
      //     code: product.code,
      //     user_id: authStore().user.uid,
      //     product_id: product.id,
      //     product_price: product.price,
      //   };

      //   fetch(this.cartAPI, { method: "POST", body: JSON.stringify(data) })
      //     .then((resp) => {
      //       if (!resp.ok) {
      //         console.error("Fetch Error! HTTP status: ", resp.text());
      //       }
      //       this.response = {
      //         'success': true,
      //         'message': resp.data.message,
      //       }
      //       console.log(this.response)
      //     })
      //     .then((data) => {
      //       console.log("BACKEND RESPONSE: ", data);
      //     })
      //     .catch((error) => console.error(error));
      // }
    },

    isLoggedIn() {
      return authStore().user ? true : false;
    },
  },
});
