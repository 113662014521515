<template>
  <div class="body">
    <div class="container">
      <div class="heading">
        <h1>Contact Us</h1>
      </div>
      <div class="contact-info">
        <div class="address">
          <b>Address:</b>
          <br>
          Srinagar, Jammu & Kashmir, 190001, India
        </div>
        <div class="mail">
          <b>Email:</b>
          <br>
          support@delightheaven.in
        </div>
      </div>
      <form class="contact-form" method="POST" @submit.prevent="post_feedback">
        <div>
          <label for="name">Name:</label>
          <input type="text" id="name" name="name" v-model="name" required>
        </div>
        <!-- <div>
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" v-model="email" required>
        </div> -->
        <div>
          <label for="subject">Subject:</label>
          <input type="text" id="subject" name="subject" v-model="subject" required>
        </div>
        <div>
          <label for="message">Message:</label>
          <textarea id="message" name="message" rows="5" v-model="message" required></textarea>
        </div>
        <button type="submit">Submit</button>

        {{ response }}
      </form>
      <div class="timings">
        <b>Customer Support Hours</b>
        <br>
        Our customer support team is available during the following hours:
        <br>
        Monday - Saturday: 9:30 AM - 5:30 PM (IST)
        <br>
        Sunday: Closed
      </div>
      <div class="socials">
        <b>Social Media</b>
        <br>
        Connect with us on Social Media:
        <ul>
          <li>
            <a href="https://www.instagram.com/delightdeheaven" target="_blank">Instagram</a>
          </li>
        </ul>
      </div>
      <div class="faq">
        <b>FAQ Section</b>
        <br>
        Have a question? Check out our <router-link to="/faq">FAQ page</router-link> for answers to common queries.
      </div>
      <div class="get-in-touch">
        <b>Get In Touch!</b>
        <br>
        We're here to help! Whether you have a question, feedback, or need assistance, feel free to reach out to us
        using the contact form above or through our social media channels. We look forward to hearing from you!
      </div>
    </div>
  </div>
</template>

<script>
import { authStore } from '@/store';
import axios from 'axios';


export default {
  name: 'ContactUs',
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      response: '',
      dev_server: 'http://localhost:3000/public/php/feedback.php',
      prod_server: 'php/feedback.php'
    }
  },
  methods: {
    post_feedback() {
      axios.post(this.prod_server, {
        name: this.name,
        email: authStore().user.email ?? '',
        subject: this.subject,
        message: this.message
      })
        .then(resp => {
          this.response = resp.data.message
          this.clearForm()
        })
        .catch(err => console.error(err))
    },
    clearForm() {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
    }
  }
}
</script>

<style scoped>
.body {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: black;
}

.container {
  text-align: start;
  max-width: 800px;
  margin: 50px auto;
  background-color: #faf4b7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column
}

.heading {
  /* text-align: center; */

  font-family: "Manrope", sans-serif;
  margin-bottom: 20px;
  color: #33691E;
}

.contact-info {
  margin-bottom: 20px;
}

.contact-info div {
  margin-bottom: 10px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.contact-form button {
  background-color: #33691E;
  /* background-color: #007bff; */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.timings {
  margin-top: 1rem;
}

.timings,
.socials,
.faq,
.get-in-touch {
  margin-bottom: 20px;
}

.socials ul {
  list-style-type: none;
  padding: 0;
}

.socials li {
  display: inline-block;
  margin-right: 10px;
}
</style>
