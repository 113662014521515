import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignupPage from "../views/SignupPage.vue";
import NotFound from "../views/404.vue";
import ContactPage from "../views/ContactPage.vue";
import ProductPage from "../components/ProductView.vue";
import CardView from "@/views/ProductLandingPage.vue";
import LoginView from "@/views/LoginPage.vue";
import CartViewOld from "@/views/Cart.vue";
import CartView from "@/views/CartPage.vue";
import UserAccount from "@/components/user/UserDetails.vue";
import { auth } from "@/main";
import FeedbackPage from "@/views/FeedbackPage.vue";
import FaqView from "@/views/FaqView.vue";
import CustomerDetails from "@/components/payment/CustomerDetails.vue";
import PaymentMethod from "@/components/payment/PaymentMethod.vue";
import { authStore } from "@/store";
import LoginPrompt from "@/views/LoginPrompt.vue";
import UserProfile from "@/views/UserProfile.vue";
import RespNav from "@/components/NavBar.vue";
import PaymentStatus from "@/components/payment/PaymentStatus.vue";
import FirestoreProds from "@/tests/Firebase_Firestore.vue";
import InitiatePayment from "@/components/payment/InitiatePayment.vue";

const getCurrentUser = () => {
  return auth.currentUser;
};

/*
In many programming languages, a single exclamation mark (!) is a logical NOT operator, 
which means it reverses the boolean value of the operand.
Applying it twice (!!) essentially converts any truthy or falsy
value to its corresponding boolean representation. */

const routes = [
  { path: "/prods", name: "prods", component: FirestoreProds },

  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Delight de Heaven",
    },
  },

  // {
  //   path: "/navbar",
  //   name: "navbar",
  //   component: Navbar,
  // },
  {
    path: "/register",
    name: "register",
    component: SignupPage,
    meta: {
      title: "Register",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login",
    },
  },

  {
    path: "/products",
    name: "products",
    component: CardView,
    meta: {
      title: "Products",
    },
  },

  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
    meta: {
      title: "Contact",
    },
  },

  {
    // path: "/products/:id",
    path: "/product",
    name: "product",
    component: ProductPage,
    meta: {
      // title: "Product"
      title: "",
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: {
      requiresAuth: true,
      title: "Cart",
    },
  },
  {
    path: "/cartold",
    name: "cartold",
    component: CartViewOld,
    meta: {
      requiresAuth: true,
      title: "Cart",
    },
  },

  {
    path: "/upload",
    name: "upload",
    component: import("@/file upload/App.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: UserAccount,
    meta: {
      requiresAuth: true,
      title: "Account",
    },
  },
  {
    path: "/reset",
    name: "reset-password",
    component: import("@/views/ResetView.vue"),
    requiresAuth: true,
    meta: {
      title: "Reset Password",
    },
  },
  // { path: "/404", component: NotFound },
  { path: "/:pathMatch(.*)*", component: NotFound },
  {
    path: "/feedback",
    name: "feedback",
    component: FeedbackPage,
    meta: {
      title: "Feedback",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
    meta: {
      title: "Faq Page",
    },
  },
  {
    path: "/user-details",
    name: "userdetails",
    component: CustomerDetails,
    meta: {
      title: "User Details",
      requiresAuth: true,
    },
  },
  {
    path: "/payment-method",
    name: "paymentmethod",
    component: PaymentMethod,
    meta: {
      title: "Payment Method",
      requiresAuth: true,
    },
  },

  {
    path: "/login-prompt",
    name: "loginprompt",
    component: LoginPrompt,
    meta: {
      title: "Login Message",
      requiresAuth: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfile,
    meta: {
      title: "Profile",
      requiresAuth: true,
    },
  },

  // init payment
  {
    path: "/initiate-payment",
    name: "init_payment",
    component: InitiatePayment,
  },

  // transaction status page
  {
    path: "/payment-status/:merchantTransactionId",
    name: "transaction-status",
    component: PaymentStatus,
    meta: {
      title: "Payment Status",
    },
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from) => {
  document.title = to.meta.title;
  if (to.meta.requiresAuth && !authStore().user) {
    // console.log(authStore().user)
    return {
      path: "/login-prompt",
      query: { redirect: to.fullPath },
    };
  }

  if (to.path.includes("/products/")) {
    return {
      query: { redirect: "/products" },
    };
  }
});

export default router;
