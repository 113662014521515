<template>
  <div class="home-gallery">
    <div class="home-gallery1" style="background-color: #FAF4B7;">
      <h1 class="home-gallery-heading heading2">Delightful Dry Fruits</h1>
      <span class="home-gallery-sub-heading">
        Explore our wide range of premium quality dry fruits
      </span>
      <div class="home-container09">
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name">
          <img alt="image"
            src="https://images.unsplash.com/photo-1633881615719-af6b11eb95d6?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name1">
          <img alt="image"
            src="https://images.unsplash.com/photo-1542706935-033eff497b2b?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name3">
          <img alt="image"
            src="https://images.unsplash.com/photo-1571181761981-0765e0328710?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name2">
          <img alt="image"
            src="https://images.unsplash.com/photo-1621817845389-472c0473423d?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name4">
          <img alt="image"
            src="https://images.unsplash.com/photo-1601368156156-fb9e52e2f5dd?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name5">
          <img alt="image"
            src="https://images.unsplash.com/photo-1599975744981-48d63c8f38af?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name6">
          <img alt="image"
            src="https://images.unsplash.com/photo-1584028624332-5b28c40d98b9?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>
        <div class="gallery-card3-gallery-card gallery-card3-root-class-name7">
          <img alt="image"
            src="https://images.unsplash.com/photo-1545303234-0063837ea931?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMTg3ODI0NXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
            class="gallery-card3-image" />
        </div>

      </div>
    </div>
  </div>
</template>
