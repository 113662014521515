<template>
  <div>
    <div class="product-card-container" v-if="itemLoaded">
      <div class="container">
        <div class="graphical">
          <div class="product-title">
            {{ product.title }}
          </div>
          <hr style="height: 1px" />

          <div class="images-gallery">
            <img
              class="main-image"
              :src="product.images[0]"
              style="width: 600px; height: 400px; border-radius: 8px"
              alt="Error loading image!"
            />
            <div class="gallery">
              <img
                v-for="image in product.images"
                :src="image"
                :key="image.path"
                @click="changeActiveImage(image)"
                :alt="product.title"
              />
            </div>
          </div>
        </div>

        <div class="details">
          <div class="product-heading">
            <div class="title">{{ product.title }}</div>
            <div class="rating">
              <!-- 4.5 -->
            </div>
            <div class="subheading">
              {{ product.description }}
            </div>
          </div>

          <div class="quantity price">
            <select id="qtySelect" v-model="selectedOption">
              <optgroup>
                <option v-for="price in Object.keys(product.price)">
                  {{ price }}
                </option>
              </optgroup>
            </select>
            {{ selectedOption }}g only for ₹{{
              product.price[selectedOption]
            }}/=
          </div>
          <div class="buttons">
            <button class="btn-cart" @click="addToCart(product)">
              Add to cart
            </button>
            <button class="btn-buy" @click="buyProduct">Buy now</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>Loading...</h1>
    </div>
  </div>
</template>

<script>
import FooterView from "./FooterView.vue";
import { addToCart } from "@/cart";
import { authStore } from "@/store";
import { productStore } from "@/store/productStore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { auth } from "@/main";
import { db } from "@/firebase/firebase.js"; // Adjust path as per your Firebase setup
import { doc, getDoc } from "firebase/firestore";
export default {
  components: {
    FooterView,
  },
  data() {
    return {
      product_id: null,
      selectedOption: null,
      images: [],
      selectedImage: null,
      product: null,

      itemLoaded: false,
      btnDisabled: false,

      // Auth
      authStore: authStore(),
      page_title: "",

      user: null,
    };
  },

  async created() {
    this.authStateChanged();

    this.productName = this.$route.query.id;
    this.page_title = this.productName;
    await this.fetchProduct();
    // this.selectedPrice = this.product.price['500g']
    this.selectedOption = Object.keys(this.product.price)[1];
  },

  mounted() {
    document.title = this.productName;

    this.scrollToTop();
  },
  methods: {
    async fetchProduct() {
      try {
        const docRef = doc(db, "products", this.productName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();
          this.product = {
            id: docSnap.id,
            title: productData.title,
            description: productData.description,
            price: productData.price,
            images: productData.images,
          };
          console.log(this.product);
        } else {
          console.log("No such product document!");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
      this.itemLoaded = true;
    },

    changeActiveImage(image) {
      this.selectedImage = image;
    },

    // auth changed
    authStateChanged() {
      const auth = getAuth();

      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.authStore.user = user;
          this.isLoggedIn = true;
          this.userEmail = user.email;
        } else {
          this.authStore.user = null;
          this.isLoggedIn = false;
          this.userEmail = null;
        }
      });
    },
    // buy product
    async buyProduct() {
      await productStore().setItems(
        [this.product.title],
        [
          {
            price: this.product.price[this.selectedOption],
            quantity: this.selectedOption,
          },
        ]
      );
      this.navigateToPaymentPage();
    },

    // add to cart
    async addToCart(product) {
      const result = await addToCart(product, this.authStore, this.isLoggedIn);
      if (result) {
        this.$moshaToast("Item added to cart", {
          type: "success",
          showIcon: true,
          timeout: 1500,
        });
      } else {
        this.$moshaToast("Some error occured", {
          type: "danger",
          showIcon: true,
          timeout: 1500,
        });
      }
    },

    navigateToPaymentPage() {
      this.$router.push({ name: "userdetails" });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    computed: {
      isLoggedIn() {
        return this.authStore.user ? true : false;
      },
      userEmail() {
        return this.isLoggedIn ? this.authStore.user.email : "";
      },
    },
  },
};
</script>

<!-- PRODUCT -->
<style scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.product-title {
  font-size: x-large;
  text-align: start;
  margin-top: 3rem;
  margin-left: 0.5rem;
}

.back-icon {
  margin: 0.5rem 1rem;
}

.images-gallery img {
  margin-top: 1.3rem;
  max-width: 100%;
  /* Make images responsive */
  height: 140px;
  border-radius: 4px;
  /* Maintain aspect ratio */
  /* object-fit: contain; */
}

.gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 0.8rem;
  overflow-x: scroll;
}

.gallery * {
  width: 180px;
  object-fit: cover;
  border: 1px solid black;
  border-radius: 8px;
}

/* PRODUCT DETAILS PORTION */

.details {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 2rem 0.5rem;
  gap: 0.5rem;
  margin: 0.5rem;
  max-width: 50%;
  flex-grow: 0;
}

.product-heading .title {
  font-size: x-large;
  text-align: start;
}

.product-heading .rating {
  font-size: large;
}

.subheading {
  line-height: 1.5;
  letter-spacing: 1px;
  margin: 0.5rem 0;
  text-align: start;
}

.quantity {
  font-size: large;
  letter-spacing: 1px;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  gap: 2rem;
  margin: 1rem 0;
}

.buttons * {
  width: 10rem;
  padding: 0.5rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.buttons *:hover {
  scale: 1.04;
  box-shadow: 1px 1px 3px grey;
  transition: all 0.3s;
}

.btn-cart {
  background: yellow;
  color: #111;
}

.btn-buy {
  background-color: lime;
  color: #111;
}

/* RESPONSIVENESS */

/* Media query for responsiveness */
@media (max-width: 768px) {
  * {
    text-align: start;
  }

  .container {
    flex-direction: column;
    /* Stack elements vertically on smaller screens */
    align-items: center;
  }

  .details {
    max-width: 100%;
  }

  .buttons {
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
    justify-content: center;
    /* Center buttons horizontally */
    gap: 1rem;
  }
}
</style>

<!-- COMMENT SECTION -->
<style scoped>
.comments-container {
  max-width: 800px;
  margin: 3rem auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #333;
}

.comments-heading {
  color: #333;
}

.comments-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.userCommentLabel {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"], */
#userCommentTextArea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-submit {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 4px solid #007bff;
  text-align: start;
  /* text-wrap: wrap; */
}

.comment strong {
  color: #333;
}

.comment p {
  margin: 5px 0;
  word-wrap: break-word;
}
</style>
