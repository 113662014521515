<template>
    <div class="spinner">

    </div>
</template>



<style>
.spinner {
    width: 30px;
    height: 30px;
    border: 3px solid black;
    border-radius: 50%;
    border-top: 3px solid white;
    animation: spin 1.5s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

