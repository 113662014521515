<template>
  <div class="container">
    <div class="item" v-for="product in products" :key="product.id">
      <ProductCard :product="product" class="m-3 my-4" />
    </div>
    <div class="msg" v-if="!itemsLoaded">
      <h2>
        <div class="loader"></div>
      </h2>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/firebase.js";
import { collection, getDocs } from "firebase/firestore";
import ProductCard from "@/components/ProductCard.vue"; // Ensure ProductCard component path is correct

export default {
  components: {
    ProductCard,
  },

  data() {
    return {
      products: [],
      itemsLoaded: false,
    };
  },

  async created() {
    await this.fetchProducts();
    console.log(this.products)
  },

  methods: {
    async fetchProducts() {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        querySnapshot.forEach((doc) => {
          this.products.push({
            id: doc.id,
            title: doc.data().title,
            description: doc.data().description,
            price: doc.data().price,
            images: doc.data().images,
          });
        });
        this.itemsLoaded = true; // Set itemsLoaded to true after data is fetched
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #faf4b7;
  gap: 20px;
  padding: 20px;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #33691E; /* Change color for loader */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
</style>
