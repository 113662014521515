<template>
  <footer v-if="correctPage">
    <div class="footer-container">
      <div class="heading">
        <div class="heading-text">
          Delight de Heaven
        </div>
        <div class="subheading">
          The essence of <b>Kashmiri</b> handpicked dryfruits brought to
          your home from the lap of <b>Heaven On Earth</b> with love.
        </div>
      </div>

      <hr>

      <div class="products-container">
        <div class="products-heading">
          Our Products
          <ul class="products-list">
            <li>Dry Fruits</li>
            <li>Dried Fruits</li>
            <li>Kernels</li>
            <li>Kashmiri Saffron</li>
          </ul>
        </div>
      </div>

      <hr>

      <div class="contact-container">
        <div class="contact-heading">
          Contact Us
        </div>
        <div class="contact-box">

          <div class="contact">
            <label><i class="fa fa-envelope mr-3"></i> contact@delightheaven.in</label>
            <label><i class="fa fa-envelope mr-3"></i> support@delightheaven.in</label>
            <!-- <abel><i class="fa fa-envelope mr-3"></i> delightdeheaven@gmail.com</abel> -->
            <!-- <label><i class="fa fa-home mr-3"></i> Srinagar Jammu and Kashmir, 190001, IN</label> -->
            <label><i class="fa fa-home mr-3"></i> Fazul Haq Colony, Hazratbal Rd, near Mee & Momee Store, Batapora,
              Srinagar, Jammu and Kashmir 190006.</label>
          </div>

          <div class="socials">
            <a class="insta-icon" href="https://www.instagram.com/delightdeheaven" role="button"><i
                class="fa fa-instagram"></i></a>
            <a class="wapp-icon" href="https://www.instagram.com/delightdeheaven" role="button"><i
                class="fa fa-whatsapp"></i></a>
          </div>

        </div>
      </div>

      <hr>



      <div class="policies-container">
        <div class="policies-heading">
          Policies and Procedures
        </div>
        <div class="policies-box">
          <ul>
            <li><a href="../../../policies/privacy-policy.html" target="_blank">Privacy Policy <i
                  class="fa fa-external-link"></i></a></li>
            <li><a href="../../../policies/shipping-policy.html" target="_blank">Shipping Policy</a><i
                class="fa fa-external-link"></i></li>
            <li><a href="../../../policies/terms-and-conditions.html" target="_blank">Terms and Conditions <i
                  class="fa fa-external-link"></i></a></li>
            <li><a href="../../../policies/return-and-refund-policy.html" target="_blank">Return and Refund Policy</a><i
                class="fa fa-external-link"></i></li>
          </ul>

        </div>
      </div>

      <hr>

      <div class="faq-container">
        <div class="faq-heading">
          FAQ Section
        </div>
        <div class="faq-box">
          You can check out the FAQs <router-link :to="{ name: 'faq' }">here<i class="material-symbols-outlined">
              link
            </i></router-link>
        </div>
      </div>

      <hr>
      <div class="ownership" style="margin-top: 2rem;margin-bottom: -2rem;">
        <label>This website is owned by <strong>Danish Gul</strong></label>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    correctPage() {
      return !["/register", "/login", "/user-details", "/payment-method", "/card-payment", "/login-prompt", "/upi-payment", "/nav"].includes(this.$route.path);
    },
  },
};
</script>

<style scoped>
a {
  color: white;
}

li {
  list-style: none;
}

footer {
  padding: 4rem 1rem;
  /* background: #ffbd59; */
  color: white;
  background: #556b2f;
  /* color: #33691e; */
}

hr {
  margin-top: 3rem;
  background-color: white;
}

.footer-conatainer {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 2rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
}

.subheading {
  font-size: 1.1rem;
}


.products-container {
  display: inline-flex;
  text-align: center;
}

.products-heading,
.contact-heading,
.policies-heading,
.faq-heading {
  font-size: 1.3rem;
  margin: 1rem 1rem;
}

.products-list {
  list-style: none;
  font-size: 1rem;
}

.products-list li {

  margin: 0.25rem 0;
  text-align: left;
}


.contact-box,
.policies-box,
.faq-box {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center
}

.contact-box .contact {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1.1rem;
  margin: 2rem 0;
  gap: 0.5rem;
}

.contact label,
.contact label * {
  margin: 0 0.5rem;
}




.socials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.fa-instagram,
.insta-icon,
.wapp-icon {
  font-size: 40px;

}

.insta-icon:hover {
  border: none;
  background: linear-gradient(to top right, #f9ce34, #ee2a7b, #6228d7);
  color: transparent;
  background-clip: text;
}

.wapp-icon:hover {
  color: #25D366;
}

.insta-icon {
  outline: none;
  background-color: #ac2bac;
  background-color: transparent;
  border: none;
}

.insta-icon:active {
  outline: none;
  border: none;
}



.policies-container * {
  color: white;
}

.policies-box ul {
  padding: 0;
}

.policies-box ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.5rem;
}

.policies-box ul li i {
  margin-top: 0.3rem;
}


.policies-container ul li a:hover {
  cursor: pointer;
  text-decoration: underline;
}


.faq-box {
  display: inline;
}

.faq-box a {
  display: inline-flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
}

.faq-box a i {
  margin-top: 0.3rem;
}

.faq-box a i:hover,
.faq-box a:hover {
  text-decoration: underline;
}
</style>
